<script setup lang="ts">
import type { EntryCollection } from "contentful";
import {
  isTypeArticle,
  type TypeArticleSkeleton,
} from "~/types/contentful";

const props = defineProps<{
  relatedArticleIds?: (string | undefined)[] | undefined;
}>();

const { data: articles } = await useLazyFetch<
  EntryCollection<TypeArticleSkeleton, "WITHOUT_UNRESOLVABLE_LINKS", "en-GB">
>("/api/articles", {
  query: {
    ...(props.relatedArticleIds ? { articleIds: props.relatedArticleIds } : {}),
  },
  transform: useCircularProtect,
});

const settings = {
  itemsToShow: 2,
  snapAlign: "start",
};

const breakpoints = {
  400: {
    itemsToShow: 2.2,
    snapAlign: "center",
  },
  1024: {
    itemsToShow: 2.2,
    snapAlign: "start",
  },
};
</script>

<!-- /news/press-release/us-sif-announces-2024-peter-desimone-student-scholarship-recipients -->

<template>
  <div>
    <Carousel
      v-bind="settings"
      :breakpoints="breakpoints"
    >
      <Slide
        v-for="articleItem in articles?.items"
        :key="articleItem.sys.id"
      >
        <CardArticle
          v-if="articleItem && (isTypeArticle(articleItem))"
          :article="articleItem"
        />
      </Slide>

      <template #addons>
        <Navigation />
      </template>
    </Carousel>

    <div class="btn-wrap btn-wrap--dark mt-8">
      <NuxtLink
        to="/news"
      >
        All news
      </NuxtLink>
    </div>
  </div>
</template>

<style scoped lang="scss">
::v-deep(.carousel__slide) {
  align-items: flex-start;
  padding-right: 20px;

  @media screen and (width >= 768px) {
    padding-right: 40px;
  }
}
</style>
